$c1: #5a398a;
$c2: #2c9fae;
$text: #888;
$danger: #d34c42;
$info: #005cd0;
$success: #54c761;

$c1_light: #8464c7;

@import "../../assets/scss/main.scss";
